var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.productsList.length > 0 || _vm.mappedProducts.length > 0)?_c('div',{key:_vm.componentKey,staticClass:"products-grid",class:'desktop-items-' + _vm.desktopItemsPerRow},[(_vm.title || _vm.newArrivalsBlockContent || (_vm.buttonText && _vm.buttonLink))?_c('div',{staticClass:"white-card",class:_vm.buttonText && _vm.buttonLink ? '' : 'title-only'},[_c('div',{staticClass:"white-card__container"},[_c('div',{staticClass:"white-card__container__text"},[_c('div',{staticClass:"white-card__container__text__title"},[_vm._v("\n          "+_vm._s(_vm.newArrivalsBlockContent && _vm.showContentFromBlocks
              ? _vm.newArrivalsBlockContent.title
              : _vm.title)+"\n        ")]),_vm._v(" "),((_vm.buttonText && _vm.buttonLink) || _vm.showContentFromBlocks)?_c('CustomButton',{attrs:{"type":"tertiary","text":_vm.newArrivalsBlockContent && _vm.showContentFromBlocks
              ? _vm.newArrivalsBlockContent['cta-text']
              : _vm.buttonText,"link":_vm.newArrivalsBlockContent && _vm.showContentFromBlocks
              ? _vm.newArrivalsBlockContent.url
              : _vm.buttonLink}}):_vm._e()],1)])]):_vm._e(),_vm._v(" "),_vm._l((_vm.productsList.length > 0
      ? _vm.productsList
      : _vm.mappedProducts),function(product,index){return _c('ProductCard',{key:index + "" + (Date.now()),staticClass:"products-grid-card",attrs:{"product":product,"loading":_vm.loading,"position":_vm.title || (_vm.buttonText && _vm.buttonLink) ? index + 1 : index,"is-in-wishlist":!!_vm.itemsInWishlist[product.sku]},on:{"addToWishlist":function($event){return _vm.toggleWishlist(product)},"addToCart":function($event){return _vm.handleAddToCart(product, !!_vm.itemsInWishlist[product.sku])}}})})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
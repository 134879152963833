<template>
  <div class="sf-footer-column">
    <SfButton
      v-focus
      type="button"
      class="sf-button--pure sf-footer-column__title smartphone-only"
      @click="toggle(title)"
    >
      {{ title }}
      <SfImage
        v-if="isColumnOpen"
        :src="addBasePath('/icons/components/minus.svg')"
        alt="minus"
        width="12"
        height="2"
        placeholder=""
      />
      <SfImage
        v-else
        :src="addBasePath('/icons/components/plus.svg')"
        alt="plus"
        width="12"
        height="12"
        placeholder=""
      />
    </SfButton>
    <div class="sf-footer-column__title desktop-only" v-text="title" />
    <transition name="sf-fade">
      <div
        :class="{
          'sf-footer-column__content--hidden-on-mobile': !isColumnOpen,
        }"
        class="sf-footer-column__content"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import { SfButton, SfImage } from '@storefront-ui/vue';
import { focus } from '@storefront-ui/vue/src/utilities/directives/';
import { addBasePath } from '~/helpers/addBasePath';

export default {
  name: 'SfFooterColumnCustom',
  directives: { focus },
  components: {
    SfButton,
    SfImage,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  inject: ['items'],
  data() {
    return {
      isColumnOpen: false,
      addBasePath,
    };
  },
  watch: {
    '$parent.isOpen': {
      handler(newVal) {
        this.isColumnOpen = newVal.includes(this.title);
      },
    },
  },
  created() {
    this.items.push(this.title);
  },
  methods: {
    toggle(payload) {
      this.$parent.toggle(payload);
    },
  },
};
</script>

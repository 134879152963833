<template>
  <div class="checkout-header">
    <SfLink
      class="checkout-header--back"
      :link="$fixUrlForCurrentLocale('/cart')"
    >
      {{ $t('Go back to your Shopping Bag') }}
    </SfLink>
    <HeaderLogo class="checkout-header--logo" />
    <a class="checkout-header--contact" href="mailto:support@biffi.com">
      {{ $t('Contact us: support@biffi.com') }}
    </a>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import HeaderLogo from '~/components/Header/HeaderLogo.vue';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CheckoutHeader',
  components: { HeaderLogo, SfLink },
});
</script>

<style lang="scss" scoped>
.checkout-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 3.75rem;
  max-width: 1440px;
  margin: 0 auto;
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: white;
  border-bottom: 1px solid var(--c-super-light-grey-2);
  &--back,
  &--contact {
    display: none;
  }
}
@include from-landscape-min {
  .checkout-header {
    height: 5.875rem;
    justify-content: space-between;
    &--back,
    &--contact {
      display: block;
      @include font-14x16;
      text-transform: uppercase;
      font-weight: var(--font-weight-light);
      margin: 0 1.75rem;
    }
  }
}
</style>

<style lang="scss">
.checkout-header {
  &--back {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

<template>
  <div
    class="products-grid"
    :class="'desktop-items-' + desktopItemsPerRow"
    v-if="productsList.length > 0 || mappedProducts.length > 0"
    :key="componentKey"
  >
    <div
      v-if="title || newArrivalsBlockContent || (buttonText && buttonLink)"
      class="white-card"
      :class="buttonText && buttonLink ? '' : 'title-only'"
    >
      <div class="white-card__container">
        <div class="white-card__container__text">
          <div class="white-card__container__text__title">
            {{
              newArrivalsBlockContent && showContentFromBlocks
                ? newArrivalsBlockContent.title
                : title
            }}
          </div>
          <CustomButton
            v-if="(buttonText && buttonLink) || showContentFromBlocks"
            type="tertiary"
            :text="
              newArrivalsBlockContent && showContentFromBlocks
                ? newArrivalsBlockContent['cta-text']
                : buttonText
            "
            :link="
              newArrivalsBlockContent && showContentFromBlocks
                ? newArrivalsBlockContent.url
                : buttonLink
            "
          />
        </div>
      </div>
    </div>
    <ProductCard
      v-for="(product, index) in productsList.length > 0
        ? productsList
        : mappedProducts"
      :key="index + `${Date.now()}`"
      :product="product"
      :loading="loading"
      :position="title || (buttonText && buttonLink) ? index + 1 : index"
      :is-in-wishlist="!!itemsInWishlist[product.sku]"
      @addToWishlist="toggleWishlist(product)"
      @addToCart="handleAddToCart(product, !!itemsInWishlist[product.sku])"
      class="products-grid-card"
    />
  </div>
</template>

<script>
import ProductCard from '~/components/Products/ProductCard.vue';
import CustomButton from '~/components/General/CustomButton.vue';
import { useProduct } from '@gemini-vsf/composables';
import { useFetch, ref, shallowRef } from '@nuxtjs/composition-api';
import { useWishlist } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';

export default {
  name: 'ProductsGrid',
  components: {
    ProductCard,
    CustomButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonLink: {
      type: String,
      default: '',
    },
    productsList: {
      type: Array,
      default: () => [],
    },
    desktopItemsPerRow: {
      type: String,
      default: '4',
      validator(value) {
        return ['2', '3', '4', '5', '6'].includes(value);
      },
    },
    pageSize: {
      type: Number,
      default: 16,
    },
    componentKey: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const {
      search: getProductList,
      loading,
      products,
    } = useProduct('newProducts');
    const mappedProducts = ref([]);
    const showContentFromBlocks = shallowRef(false);

    const { itemsInWishlist, toggleWishlist, loadItemsFromList } =
      useWishlist();

    const handleAddToCart = async (product, productIsInWishlist) => {
      if (productIsInWishlist) await toggleWishlist(product);
    };
    const newArrivalsBlockContent = ref();

    useFetch(async () => {
      if (props.productsList?.length) {
        mappedProducts.value = props.productsList;
        await loadItemsFromList(props.productsList);
        return;
      }

      if (props.data && Object.keys(props.data).length > 0) {
        newArrivalsBlockContent.value =
          props.data?.it?.active && locale === 'it'
            ? props.data.it
            : props.data;
        showContentFromBlocks.value = true;
      }

      await (newArrivalsBlockContent?.value?.collection &&
      showContentFromBlocks.value
        ? getProductList({
            queryType: 'LIST',
            pageSize: 15,
            filter: {
              category_uid: {
                eq: `grn:product-list:product-list::${newArrivalsBlockContent.value.collection}`,
              },
            },
            customQuery: {
              products: 'productListCustom',
            },
          })
        : getProductList({
            queryType: 'LIST',
            pageSize: props.pageSize,
            currentPage: 1,
            sort: {
              creation: 'DESC',
            },
            customQuery: {
              products: 'productListCustom',
            },
          }));

      if (products.value?.items?.length) {
        mappedProducts.value = products.value.items.filter(Boolean);
        await loadItemsFromList(mappedProducts.value);
      }
    });

    return {
      mappedProducts,
      loading,
      itemsInWishlist,
      toggleWishlist,
      handleAddToCart,
      newArrivalsBlockContent,
      showContentFromBlocks,
    };
  },
};
</script>

<style lang="scss" scoped>
.products-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 0;
  margin-bottom: 0;
  border-left: var(--grey-border);
  .white-card {
    display: grid;
    border-right: var(--grey-border);
    border-bottom: var(--grey-border);
    &__container {
      margin: auto;
      text-align: center;
      max-width: 18rem;
      @include to-portrait-max {
        max-width: 8rem;
      }
      &__text {
        &__title {
          @include font-25x33;
          text-transform: uppercase;
          margin-bottom: var(--space-xxs);
        }
      }
    }
  }
  @include to-portrait-max {
    .title-only {
      .white-card__container {
        max-width: 14.125rem;
        &__text {
          &__title {
            @include font-20x27;
          }
        }
      }
    }
  }
}
@include from-landscape-min {
  .desktop-items {
    &-2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &-4 {
      grid-template-columns: repeat(4, 1fr);
    }
    &-5 {
      grid-template-columns: repeat(5, 1fr);
    }
    &-6 {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
</style>
